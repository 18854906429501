import * as React from "react";
import Hero from "../components/Hero/Hero";
import Seo from "../components/Seo";
import "./style.css";

export default function Home() {
  return (
    <>
      {/* HERO */}
      <h1 style={{ display: "none" }}>
        Sutra.hr Kreativna agencija za marketing, web dizajn, branding
      </h1>
      <Seo
        title="Home"
        image="http://sutrashop.com.hr/wp-content/uploads/2021/05/1000px-200.jpg"
      />
      <Hero />
    </>
  );
}
